import React from "react";
import classNames from "classnames";
import { Container, Text, AnimateIn } from "@atoms";
import { AppLink } from "@base";

const InfoGrid = ({ className: _className, paddingBottom, items }) => {
  return (
    <section
      className={classNames(
        "pt-12",
        {
          "pb-20 md:pb-28": paddingBottom,
          "pb-12": !paddingBottom,
        },
        _className
      )}
    >
      <Container>
        <div className="grid grid-cols-1 gap-y-8 sm:grid-cols-2 sm:gap-y-12 md:grid-cols-3">
          {items?.map((item, i) => {
            const { heading, descriptor, text, url } = item;
            if (url || descriptor) {
              return (
                <AnimateIn
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  preset="fadeUpFast"
                  delay={0.2 * (i + 1)}
                  className={classNames(
                    "flex flex-col gap-2 border-l-2 border-black py-2 pl-4 sm:py-0",
                    {
                      "md:border-l-0 md:pr-10 md:pl-0": i % 3 === 0,
                      "md:border-l-2 md:pl-10": i % 3 !== 0,
                      "md:pr-10": i % 3 === 1,
                      "sm:border-l-0 sm:pl-0 sm:pr-10": i % 2 === 0,
                      "sm:pl-10": i % 2 !== 0,
                    }
                  )}
                >
                  {heading && <Text variant="h4">{heading}</Text>}
                  {url?.length ? (
                    <AppLink to={url} icon={false}>
                      <Text className="underline decoration-transparent decoration-2 underline-offset-4 duration-300 hover:decoration-green">
                        {text}
                      </Text>
                    </AppLink>
                  ) : (
                    <Text className="leading-tight">{descriptor}</Text>
                  )}
                </AnimateIn>
              );
            }
            return null;
          })}
        </div>
      </Container>
    </section>
  );
};

InfoGrid.defaultProps = {};

export default InfoGrid;
